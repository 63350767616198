<template>
  <div class="faqSection">
    <div class="container">
      <animated-component>
        <div class="heading">FAQ</div>
      </animated-component>
      <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
        <animated-component>
          <DropdownItem
            class="faq"
            :title="item.title"
            :description="item.description"
          />
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownItem from './DropdownItem.vue';
export default {
  components: {
    DropdownItem
  },

  setup() {
    const faq = [
      {
        title: 'How and Who can buy an Astro Bull?',
        description: [
          'Once we launch you are able to mint Astro Bulls from our website (need website address) using metamask or walletconnect for a fixed price of 0.08 ETH each + gas. They will be random and a maximum of 15 per transaction will be set. When we are sold out they will only be on the secondary market on https://opensea.io/.'
        ]
      },
      {
        title: 'When does the sale start?',
        description: [
          "The time and date will be revealed soon, ensure to watch our social media outlets so you don't miss it."
        ]
      },
      {
        title: 'What are the Licensing rights / Intellectual property?',
        description: [
          'Astro Bull holders are given commercial rights to their specific Astro Bull. You can sell its likeness, make derivatives of it or you can give others permission to use it. It’s yours.We’ve given licensing rights over for the Astro Bulls, but not our logo, names (Astro Bulls, Astro Frens), site images, or other marketing material. If it isn’t your Astro Bull, it isn’t your Intellectual Property. Most of these logos, names, and images related to the Astro Frens/ Astro Bulls have either trademarked pending status, or are the intellectual property of the artist(s).',
          'That said, just because our logo may appear somewhere on your Astro Bull, that does not give you the right to use that logo other than on the Astro Bull itself or on a derivative of that Astro Bull. You can’t take the logo and use it to brand an item for sale or use it for marketing of a product. (We don’t mind personal use or fan art usage of our logos, just so long as you don’t try and sell them.) The bottom line here is to protect the club, it is important that someone can’t pretend to be Astro Frens/ Astro Bulls and that they can’t pretend to be collaborating with us.',
          'Regarding the term “Astro Bull”. We allow usage of the term Astro Bull when used in conjunction with your Astro bull #. For instance, you can’t use Astro Bull alone on a merch or NFT, but you can use Astro Bull #2243, Astro Bull #1185 etc.',
          'Think of it like this: You own your Astro Bull, not Astro Bull, so accurately representing yourself / brand / product, while not infringing on our brand, is important.'
        ]
      },
      {
        title: 'How do I claim my free Rickstro Bull?',
        description: [
          '2 weeks after selling out we will put a Rickstro Bull claim portal live. The Rickstro Bull claim portal will allow you to claim your new Rickstro Bull NFT and allow you to check other Astro Bull IDs which will enable you to see if they have already claimed their Rickstro Bull or not.',
          "To claim if you have an 'unclaimed Astro Bull' (meaning the Astro Bull owner has never claimed their Rickstro Bull NFT) you are eligible to claim and mint a free Rickstro Bull Just paying for gas. After you select which Astro Bull ID you are claiming for (the Astro Bulls you have in your wallet if multiple), that Astro Bull ID will be unable to claim another Rickstro Bull.",
          'If buying from the secondary market please check the ID of the Astro Bull to see if the Rickstro Bull has been claimed or not.'
        ]
      },
      {
        title: 'Do i lose my Astro Bull if i claim a Rickstro Bull?',
        description: [
          'NO, claiming a Rickstro Bull gives you an additional free NFT which is your Rickstro Bull.'
        ]
      },
      {
        title: 'Can I burn my Rickstro Bull?',
        description: ['No, not at this time.']
      },
      {
        title:
          'How are you determining who gets the community grant and how much?',
        description: [
          'We will be taking applications from the community! We are committed to the long term vision of expanding the Astroverse and want to fund exciting projects that assist with this aim. The process of submissions will be posted at a later date and we will provide the best entries to the Astro Bull community that can use their Astro Bull NFT to vote on what path is chosen.'
        ]
      },
      {
        title: 'When can I burn my Astro Bull?',
        description: [
          "The Astro Frens 'burn portal', aka the grill, will be announced when it opens."
        ]
      },
      {
        title: 'Do I lose my Astro Bull if I burn it?',
        description: [
          "YES, you destroy the original Astro Bull NFT. If you use our 'burn portal' you will receive back 2 Astro Burger NFTs when burning."
        ]
      },
      {
        title: 'How will minting work?',
        description: [
          "We will mint an 'unrevealed' Astro Bull NFT. On the reveal day we will be setting the real metadata live and users will be able to 'refresh metadata' on https://opensea.io/ to see their revealed Bull!"
        ]
      },
      {
        title: "Are Astro Bulls NFT's on ERC-721 or ERC-1155?",
        description: ['They are  ERC-1155']
      },
      {
        title: 'Why are you using ERC-1155 instead of ERC-721s?',
        description: [
          "We love ERC-721, it's a great standard. So is ERC-1155. The main difference between ERC-721 and ERC-1155 is the ability of 1155 to manage both NFTs and fungible tokens in the same contract. We've chosen to use 1155 to maintain flexibility for potential new features well into the future."
        ]
      },
      {
        title: 'Where can I view my Astro Bull after purchasing it?',
        description: [
          "You can see your Astro Bull in your 'My Frens' tab on the website or you can view it on https://opensea.io/ and go to your Profile. "
        ]
      },
      {
        title: 'How will the community treasury work?',
        description: [
          'Holders of Astro Bulls will be able to vote on what is done with the funds, whether it be purchasing the lowest priced Astro Bulls from the open market to lock in the vault, producing creative content or other intergalactic initiatives! The choice will be made entirely by the community. In addition to the ETH put into the community treasury from the road map, 10% of secondary market royalties will be placed in the community treasury as well.'
        ]
      },
      {
        title: 'Will there be other Astro Bulls in the future?',
        description: ['No! This is the only series that will contain Bulls.']
      },
      {
        title: 'Will there be other characters in the future?',
        description: [
          'Yes there will be other Astro Frens characters, what they will be though is a mystery and only time will tell what is in store for this journey.'
        ]
      },
      {
        title: "Do Astro Bull's breed?",
        description: [
          'Breeding is not currently on the roadmap, get your mind out of the gutter!'
        ]
      },
      {
        title: 'What is the royalty %?',
        description: ['2.5%.']
      }
      // Rickstro Bull FAQ
    ];

    return {
      faq
    };
  }
};
</script>
<style scoped lang="scss">
.faqSection {
  padding: 60px 20px;
  background-color: black;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 600px) {
  .faqSection .container .dropdownWrapper .faq {
    margin: 20px 0;
  }
}
</style>
