<template>
  <div class="fairDropSection">
    <div class="container">
      <animated-component>
        <img
          src="https://astrofrens-metadata.s3.amazonaws.com/assets/no-bullshit.png"
          loading="lazy"
          alt="No Bullshit"
          class="icon"
        />
      </animated-component>
      <animated-component>
        <div class="heading">Fair Drop</div>
      </animated-component>
      <animated-component>
        <div class="paragraph">
          <em
            >No whitelists, no pricing tiers, no BULLSHIT (wait… maybe some of
            that)<br /><br /></em
          >Astro Bulls are priced at a flat rate of <strong>0.08 ETH</strong>,
          trained in Moo Thai to handle any situation that may present itself
          and fully equipped for their explorations ahead. It’s time to launch!
        </div>
      </animated-component>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.fairDropSection {
  padding: 60px 20px;
  background-color: #1b2050;
  color: #fff;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .icon {
      display: block;
      width: 100px;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
    }

    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .paragraph {
      max-width: 60ch;
      margin-right: auto;
      margin-left: auto;
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
