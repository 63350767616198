<template>
  <div id="benefits" class="rickstroSection">
    <div class="container">
      <animated-component>
        <div class="heading">
          Rickstro Bulls
        </div>
      </animated-component>
      <animated-component animationType="zoom">
        <img
          src="https://astrofrens-metadata.s3.amazonaws.com/assets/rickstro-new.gif"
          loading="lazy"
          alt="Rickstro Bulls Image"
          class="image"
        />
      </animated-component>
      <animated-component>
        <div class="paragraph">
          <br /><br /><em class="italic"
            >Rickstro Bulls are brought into our dimension by Mike Vasquez, a
            California born artist and illustrator most known for his work on
            Rick and Morty official comics.</em
          >
        </div>
      </animated-component>
    </div>

    <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
      <animated-component>
        <DropdownItem
          class="faq"
          :title="item.title"
          :description="item.description"
          :isLight="true"
        />
      </animated-component>
    </div>
  </div>

  <div class="ethosSection">
    <div class="container">
      <animated-component>
        <img
          src="https://astrofrens-metadata.s3.amazonaws.com/assets/friendship.svg"
          loading="lazy"
          alt="Ethos Icon"
          class="icon"
        />
      </animated-component>
      <animated-component>
        <div class="heading">
          Astro Frens Ethos
        </div>
      </animated-component>
      <animated-component>
        <div class="paragraph">
          This project is about making frens and helping each other out.
          <br />Nothing is impossible if we stick together.
        </div>
      </animated-component>
    </div>
  </div>
</template>
<script>
// Icon imports.
import router from '/src/router';

// Component imports.
import Button from '/src/components/ui/Button.vue';
import DropdownItem from './DropdownItem.vue';

export default {
  components: {
    DropdownItem
  },

  setup() {
    const navigateTo = route => {
      router.push(route);
    };

    const faq = [
      {
        title: 'How do I claim a Rickstro Bull?',
        description: [
          "If have an Astro Bull you are able to claim a Rickstro Bull for free. You just have to pay gas fees. Visit the 'Claim' page and select the Astro Bulls you want to claim."
        ]
      },
      {
        title: 'When does Rickstro Bull claiming end?',
        description: ['Claiming ends on August 18th at 22:00 PM UTC.']
      },
      {
        title: 'Do the Rickstro Bulls have randomly-generated traits?',
        description: [
          'Yes there are hundreds of cool new traits and all Rickstro Bulls will be randomly generated.'
        ]
      },
      {
        title: 'How do I check if an Astro Bull has already been claimed?',
        description: [
          'Enter in your token ID on the claim page to see if that Astro Bull has already claimed a Rickstro Bull. To get your token ID visit your Astro Bulls on Opensea and click the details tab (on the left column under the metadata and about Astro Frens tabs) and you will see your token ID. Click your token ID to copy.'
        ]
      },
      {
        title: 'What happens if I miss the claim?',
        description: [
          'If you dont redeem your claim during the claiming period unfortunately you will be unable to claim in the future The unclaimed Rickstro Bulls will not be minted.'
        ]
      }
    ];

    return {
      navigateTo,
      faq
    };
  }
};
</script>
<style scoped lang="scss">
.rickstroSection {
  padding: 60px 20px;
  background-color: #d9d9e3;
  background-image: linear-gradient(
      180deg,
      rgba(217, 217, 227, 0.95),
      rgba(217, 217, 227, 0.95)
    ),
    url('https://astrofrens-metadata.s3.amazonaws.com/assets/rickstro-large.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: #4586cf;
    }

    .image {
      display: block;
      max-width: 500px;
      width: 100%;
      margin-right: auto;
      margin-bottom: 30px;
      margin-left: auto;
      border-radius: 30px;
      box-shadow: 12px 12px 30px 0 rgb(0 0 0 / 35%);
    }

    .paragraph {
      max-width: 60ch;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      color: #000;
      font-size: 20px;
      text-align: center;

      .italic {
        opacity: 0.6;
        font-size: 18px;
      }
    }

    .button {
      text-align: center;
    }
  }
}

.ethosSection {
  padding: 60px 20px;
  display: flex;
  min-height: 30vh;
  align-items: center;
  background-color: #ffd915;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .icon {
      display: block;
      width: 100px;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
    }

    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: black;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .paragraph {
      max-width: 60ch;
      margin-right: auto;
      margin-left: auto;
      color: #000;
      font-size: 30px;
      text-align: center;
      opacity: 0.8;
    }
  }
}
</style>
