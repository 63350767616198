<template>
  <Ethereum :callback="initialize" />
  <div class="burn">
    <div class="container">
      <div class="descriptionWrapper">
        <div class="title">Burn</div>
        <div class="subtitle">
          Burning your Astro Bull will generate 2 Astro Burger NFTs.<br />Select
          the bulls you would like to burn:
        </div>
      </div>

      <div class="itemsGrid">
        <NFTCard
          :fren="fren"
          v-for="fren in frens"
          :key="fren.id"
          @click="toggleSelection(fren)"
          :class="fren.isSelected ? 'selected' : ''"
        />
      </div>

      <LoadingSpinnerIcon v-if="loading" />

      <div class="burnButtonWrapper">
        <Button v-if="selectedFrens.length > 0" @click="burn">
          Burn {{ selectedFrens.length }} {{ bullLabel }}
        </Button>
      </div>
      <div class="faq">FAQ</div>
      <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
        <animated-component>
          <DropdownItem :title="item.title" :description="item.description" />
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// Component imports.
import NFTCard from '../my-frens/components/NFTCard.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import Button from '/src/components/ui/Button.vue';
import DropdownItem from '/src/pages/home/components/DropdownItem.vue';
import LoadingSpinnerIcon from '/src/components/icons/LoadingSpinnerIcon.vue';

export default {
  components: {
    NFTCard,
    Ethereum,
    Button,
    DropdownItem,
    LoadingSpinnerIcon
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const selected = ref();

    const loading = ref(false);
    if (store.state.mint.frens.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.frens,
      selection => {
        loading.value = false;
      }
    );

    // Retrieve the the user's owned frens.
    const initialize = () => {
      const address = store.state.ethers.address;
      const groupId = 1;
      store.dispatch('mint/getFrens', { address, groupId }, { root: true });
    };

    const frens = computed(() => {
      return store.state.mint.frens;
    });

    const toggleSelection = fren => {
      fren.isSelected = !fren.isSelected;
    };

    const selectedFrens = computed(() => {
      let frenIds = [];
      for (let fren of frens.value) {
        if (fren.isSelected) frenIds.push(fren.id);
      }
      return frenIds;
    });

    const bullLabel = computed(() => {
      return selectedFrens.value.length === 1 ? 'Bull' : 'Bulls';
    });

    const burn = () => {
      router.push({
        name: 'Confirm Burn',
        params: {
          frens: selectedFrens.value
        }
      });
    };

    const faq = [
      {
        title: 'How and Who can buy an Astro Bull?',
        description: [
          'Once we launch you are able to mint Astro Bulls from our website (need website address) using metamask or walletconnect for a fixed price of 0.08 ETH each + gas. They will be random and a maximum of 15 per transaction will be set. When we are sold out they will only be on the secondary market on https://opensea.io/.'
        ]
      }
    ];

    return {
      frens,
      store,
      initialize,
      loading,
      selected,
      toggleSelection,
      selectedFrens,
      bullLabel,
      burn,
      faq
    };
  }
};
</script>
<style scoped lang="scss">
.burn {
  padding: 60px 20px;
  color: white;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .descriptionWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        margin-top: 0px;
        margin-bottom: 20px;
        font-family: Montserrat, sans-serif;
        color: #ffd915;
        font-size: 90px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .itemsGrid {
      display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;

      .selected {
        box-shadow: 0 0 0 4px #15fff3;
      }
    }

    .burnButtonWrapper {
      position: sticky;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      display: grid;
      min-height: 60px;
      margin-bottom: 40px;
      padding: 60px 30px;
      justify-content: center;
      justify-items: center;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background-image: linear-gradient(0deg, #000 50%, transparent);
    }

    .faq {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
</style>
