<template>
  <div class="aboutSection">
    <animated-component>
      <div class="about">
        Astro Frens are an intergalactic series of
        <strong>deflationary NFT collections</strong>, united in their own
        unique metaverse — the Astroverse.
      </div>
    </animated-component>
    <swiper
      :effect="effect"
      :slides-per-view="3"
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: true }"
      @swiper="onSwiper"
      :autoplay="autoplay"
      :keyboard="keyboard"
      :loop="loop"
      :speed="speed"
      @slideChange="onSlideChange"
      class="swiper"
    >
      <swiper-slide v-for="image in images" :key="image">
        <div class="picture">
          <img :src="require(`@/images/${image}`)" alt="" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Imports.
import SwiperCore, {
  EffectCoverflow,
  Keyboard,
  Autoplay,
  Pagination
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';

// Configure Effect.
EffectCoverflow.params.coverflowEffect.depth = 350;
EffectCoverflow.params.coverflowEffect.rotate = 20;
EffectCoverflow.params.coverflowEffect.stretch = 0;
EffectCoverflow.params.coverflowEffect.modifier = 1;
EffectCoverflow.params.coverflowEffect.slideShadows = false;

SwiperCore.use([EffectCoverflow, Pagination, Autoplay, Keyboard]);

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      effect: 'coverflow',
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      mousewheel: false,
      slidesPerView: 'auto',
      autoplay: {
        delay: 800,
        disableOnInteraction: false
      },
      speed: 800,
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },

      images: [
        'swiper_images/astro1.jpg',
        'swiper_images/astro2.jpg',
        'swiper_images/astro3.jpg',
        'swiper_images/astro4.jpg',
        'swiper_images/astro5.jpg',
        'swiper_images/astro6.jpg',
        'swiper_images/astro7.jpg',
        'swiper_images/astro8.jpg',
        'swiper_images/astro9.jpg',
        'swiper_images/astro10.jpg'
      ]
    };
  },
  methods: {
    onSwiper(swiper) {},
    onSlideChange() {}
  }
};
</script>
<style scoped lang="scss">
.aboutSection {
  background-color: black;
  color: #fff;
  .about {
    padding: 60px 20px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    max-width: 56ch;
    margin-right: auto;
    margin-left: auto;
    font-size: 28px;
    text-align: center;
  }

  .swiper {
    cursor: grab;
    padding-bottom: 60px;
    perspective: 1200px;
    flex: 0 0 auto;
    .picture {
      display: flex;
      overflow: hidden;
      max-width: 600px;
      width: 40vw;
      min-width: 400px;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 20px;
        object-position: 50% 50%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection .swiper .picture {
    width: 300px;
  }
}

@media (max-width: 800px) {
  .aboutSection .swiper {
    margin-left: -40vw;
  }
}

@media screen and (max-width: 479px) {
  .swiper-slide {
    max-width: 75%;
  }

  .aboutSection .swiper {
    margin-left: -52vw;
  }

  .aboutSection .swiper .picture img {
    width: auto;
    max-width: 70vw;
  }
}
</style>
