<template>
  <animated-component>
    <div class="roadmapListItem" :class="isDone ? 'complete' : ''">
      <div class="titleWrapper" :style="titleWrapperStyles">
        <img
          :src="data.image"
          :style="imageStyles"
          loading="lazy"
          alt="Roadmap Icon"
          class="image"
        />
        <div class="title" :style="titleStyles">{{ data.title }}</div>
      </div>
      <div class="subtitle" :style="subtitleStyles">
        {{ data.subtitle }}
      </div>
      <div class="percent" :style="percentageStyles">
        <div>{{ data.percentage }}</div>
        <div v-if="isDone" class="done">
          <img
            src="https://assets.website-files.com/60e49b88acdbeb1a692212d2/60fb26e2f630709a19664178_check.svg"
            loading="lazy"
            alt="Checkmark"
            class="checkmark"
          />
          <div>DONE!</div>
        </div>
      </div>
    </div>
  </animated-component>
</template>
<script>
export default {
  props: {
    data: Object,
    isLast: Boolean,
    isDone: Boolean
  },

  computed: {
    imageStyles() {
      return this.isLast
        ? {
            display: 'block',
            maxWidth: '140px',
            maxHeight: '140px',
            height: '140px',
            marginTop: '40px',
            marginBottom: '20px',
            borderRadius: '50%',
            boxShadow: '3px 3px 0 0 #000'
          }
        : {};
    },

    titleStyles() {
      return this.isLast ? {} : {};
    },

    subtitleStyles() {
      return this.isLast ? { textAlign: 'center' } : {};
    },

    titleWrapperStyles() {
      return this.isLast ? { flexDirection: 'column' } : {};
    },

    percentageStyles() {
      return this.isLast
        ? { backgroundImage: 'linear-gradient(180deg, #00b95a, #136316)' }
        : {};
    }
  }
};
</script>
<style scoped lang="scss">
.roadmapListItem {
  position: relative;
  max-width: 900px;
  margin-top: 45px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px;
  border-style: solid;
  border-width: 2px;
  border-color: #3a3a3a;
  border-radius: 30px;
  background-color: #1b2050;

  .titleWrapper {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .image {
      height: 40px;
      margin-right: 10px;
    }

    .title {
      font-size: 22px;
      color: #15fff3;
      font-weight: 700;
    }
  }

  .subtitle {
    opacity: 0.8;
    font-size: 18px;
  }

  .percent {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0px;
    display: flex;
    width: 90px;
    height: 90px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #fff;
    border-radius: 50%;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#a000f8),
      to(#6800b2)
    );
    background-image: linear-gradient(180deg, #a000f8, #6800b2);
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
    font-weight: 600;

    .done {
      position: absolute;
      bottom: -6.5px;
      display: flex;
      padding: 6px 10px;
      align-items: center;
      border-style: solid;
      border-width: 2px;
      border-color: #fff;
      border-radius: 30px;
      background-color: #2ec745;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;

      .checkmark {
        height: 12px;
        margin-right: 5px;
      }
    }
  }
}

.complete {
  border-color: #2fc644;
}
</style>
