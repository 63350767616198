<template>
  <section
    class="dropDownMenuWrapper"
    :class="{ 'dropDownMenuWrapper-open': isOpen }"
    :style="wrapperColor"
  >
    <div>
      <div
        class="dropDownMenuButton"
        :class="{ 'dropDownMenuButton-open': isOpen }"
        :style="textColor"
        ref="menu"
        @click="openClose"
      >
        {{ title }}
      </div>
      <div class="iconWrapper" @click="openClose">
        <div class="plus-icon">
          <div
            class="bar1"
            :class="{ 'bar1-open': isOpen }"
            :style="backgroundColor"
          />
          <div
            class="bar2"
            :class="{ 'bar2-open': isOpen }"
            :style="backgroundColor"
          />
        </div>
      </div>
    </div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <section class="dropdownMenu" v-show="isOpen">
        <div
          class="text"
          :style="textColor"
          v-for="line in description"
          :key="line"
        >
          {{ line }}
        </div>
      </section>
    </transition>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false // Variable if the menu is open or closed
    };
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: Array
    },
    isLight: {
      type: Boolean
    }
  },

  computed: {
    textColor() {
      return this.isLight ? { color: 'black' } : {};
    },

    wrapperColor() {
      return this.isLight ? { borderColor: '#ccc' } : {};
    },

    backgroundColor() {
      return this.isLight ? { background: 'black' } : {};
    }
  },

  methods: {
    openClose() {
      // Toggle between open or closed ( true || false )
      this.isOpen = !this.isOpen;
    },
    enter(el) {
      el.style.height = 'auto';
      const height = getComputedStyle(el).height;
      el.style.height = 0;

      getComputedStyle(el).height;

      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    leave(el) {
      el.style.height = getComputedStyle(el).height;
      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dropDownMenuWrapper {
  margin-top: 10px;
  position: relative;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.8);

  * {
    box-sizing: border-box;
    text-align: left;
  }

  .dropDownMenuButton {
    border: none;
    font-size: inherit;
    outline: none;

    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin: 0;
    line-height: 1;
    width: 100%;
    height: 57px;
    z-index: 2;
    cursor: pointer;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.3em;
    text-align: left;
    font-family: 'Work Sans', sans-serif;
    transition: all 0.5s ease;
    padding: 40px 0px;

    &:hover {
      opacity: 0.8;
    }
  }

  .dropDownMenuButton:hover {
    opacity: 0.8;
  }

  .dropDownMenuButton:active {
    opacity: 0.6;
  }

  .dropDownMenuButton-open {
    border-bottom-width: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .iconWrapper {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 5px;
    top: 40px;
    z-index: 3;

    .plus-icon {
      position: relative;
      width: 25px;
      height: 25px;
    }

    .bar1 {
      position: absolute;
      width: 100%;
      max-width: 14px;
      height: 2px;
      background: white;
      top: 24%;
      left: 50%;
      border-radius: 9999px;
      transform: rotate(0deg);
      transition: all 0.5s ease;
    }

    .bar1-open {
      transform: rotate(-315deg);
      margin-top: 0;
      background: white;
    }

    .bar2 {
      position: absolute;
      height: 100%;
      max-height: 14px;
      width: 2px;
      background: white;
      left: 74%;
      border-radius: 9999px;
      transform: rotate(0deg);
      transition: all 0.5s ease;
    }

    .bar2-open {
      transform: rotate(-135deg);
      background: white;
    }
  }

  .iconWrapper:hover {
    cursor: pointer;
  }
  .dropdownMenu--dark {
    background: white;
    border: none;

    .option {
      border-bottom: 1px solid #888;
    }

    * {
    }
  }

  @keyframes menu {
    from {
      transform: translate3d(0, 30px, 0);
    }
    to {
      transform: translate3d(0, 20px, 0);
    }
  }
}

.dropdownMenu {
  top: -21px;
  position: relative;
  width: 100%;
  min-width: 300px;
  animation: menu 0s ease forwards;
  overflow: hidden;
  color: white;

  .text {
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    opacity: 0.8;
    padding-bottom: 40px;
  }

  .option {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    cursor: default;
    z-index: 2;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      border-top: 1px solid rgba(var(--text-color-rgb), 0.2);
    }

    * {
      color: inherit;
      text-decoration: none;
      background: none;
      border: 0;
      padding: 0;
      outline: none;
      cursor: default;
    }

    img {
      height: 17px;
      width: 17px;
      opacity: 0.5;
    }
  }

  .clickable-option {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
    color: rgba(var(--text-color-rgb), 0.5);
    text-decoration: none;
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.2s ease;

    &:last-child {
      border-bottom: 0;
    }

    * {
      color: inherit;
      text-decoration: none;
      background: none;
      border: 0;
      padding: 0;
      outline: none;
    }

    img {
      height: 17px;
      width: 17px;
      opacity: 0.5;
    }
  }

  .clickable-option:hover {
    background: rgba(var(--text-color-rgb), 0.1);
    text-decoration: underline;
    cursor: pointer;
  }

  .clickable-option:active {
    background: rgba(var(--text-color-rgb), 0.2);
    cursor: pointer;
  }

  .metadata-descriptor {
    margin-right: 6px;
    color: rgba(var(--text-color-rgb), 0.5);
  }

  .metadata-value {
    font-weight: 700;
  }

  .desc {
    opacity: 0.5;
    display: block;
    width: 100%;
    font-size: 14px;
    margin: 3px 0 0 0;
    cursor: default;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
}

@media (max-width: 400px) {
  .dropDownMenuWrapper {
    .dropDownMenuButton {
      width: 90%;
    }
  }

  .dropdownMenu .text {
    width: 90%;
  }
}
</style>
