<template>
  <div class="clubBenefits">
    <div class="container">
      <animated-component>
        <div class="heading">Club Benefits</div>
      </animated-component>
      <animated-component>
        <div class="subtitle">
          The membership key to the Astro Frens club is the Astro Bull. Owning
          an Astro Bull gives you exclusive access to future Astro Frens drops,
          the right to participate in NFT Governance and events set out on the
          roadmap. By owning a bull, you have the ability to shape the future of
          an ecosystem with endless possibilities.
        </div>
      </animated-component>

      <div class="benefitsGrid">
        <animated-component animationType="left" class="wrapper">
          <div v-tilt class="benefit">
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/future-frens.jpeg"
              loading="lazy"
              alt="Bull Benefit Image"
              class="benefitImage"
            />
            <div class="contentWrapper">
              <div class="title">Future Frens</div>
              <div class="description">
                There are more Frens coming! <br /><br />Owning an Astro Bull
                will give you priority access to mint subsequent Astro Frens
                when the are released.
              </div>
            </div>
          </div>
        </animated-component>
        <animated-component animationType="fade" class="wrapper">
          <div v-tilt class="benefit">
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/deflationary.jpeg"
              loading="lazy"
              alt="Bull Benefit Image"
              class="benefitImage"
            />
            <div class="contentWrapper">
              <div class="title">Deflationary</div>
              <div class="description">
                Burn your Bull and receive 2 burger NFTs in return. Royalties
                from secondary sales will be used to buy Bulls back and burn
                them.<br /><br />The bulls that are burnt will be removed from
                circulation forever.
              </div>
            </div>
          </div>
        </animated-component>
        <animated-component animationType="right" class="wrapper">
          <div v-tilt class="benefit">
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/copyright.jpeg"
              loading="lazy"
              alt="Bull Benefit Image"
              class="benefitImage"
            />
            <div class="contentWrapper">
              <div class="title">YOU OWN THE COPYRIGHT</div>
              <div class="description">
                Astro Bull holders are given commercial rights to their own
                Bull.
              </div>
            </div>
          </div>
        </animated-component>

        <animated-component animationType="left" class="wrapper">
          <div v-tilt class="benefit">
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/governance.jpeg"
              loading="lazy"
              alt="Bull Benefit Image"
              class="benefitImage"
            />
            <div class="contentWrapper">
              <div class="title">NFT Governance</div>
              <div class="description">
                Each bull is not only a membership key, it represents the
                ability to vote and guide the future of the Astro Frens
                community. The treasury could be used for buy backs, merch
                drops, content creation and more.
              </div>
            </div>
          </div>
        </animated-component>
        <animated-component animationType="fade" class="wrapper">
          <div v-tilt class="benefit">
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/club-access.jpeg"
              loading="lazy"
              alt="Bull Benefit Image"
              class="benefitImage"
            />
            <div class="contentWrapper">
              <div class="title">ASTRO FRENS CLUB ACCESS</div>
              <div class="description">
                Members only Discord, future mints, Decentraland and Sandbox
                perks, and much more to come.
              </div>
            </div>
          </div>
        </animated-component>
        <animated-component animationType="right" class="wrapper">
          <div v-tilt class="benefit">
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/merch.jpeg"
              loading="lazy"
              alt="Bull Benefit Image"
              class="benefitImage"
            />
            <div class="contentWrapper">
              <div class="title">MERCH DROPS</div>
              <div class="description">
                Future merch drops exclusively for Astro Frens members.<br /><br />

                We have an exclusive statue collectible we will be offering to
                members.
              </div>
            </div>
          </div>
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.clubBenefits {
  padding: 60px 20px;
  background-color: black;
  color: #fff;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .subtitle {
      max-width: 60ch;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 40px;
      font-size: 18px;
      text-align: center;
    }

    .benefitsGrid {
      display: grid;
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
      justify-content: center;
      grid-auto-columns: 1fr;
      grid-column-gap: 2vw;
      grid-row-gap: 2vw;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;

      .benefit {
        display: flex;
        overflow: hidden;
        height: 100%;
        flex-direction: column;
        align-items: center;
        border-style: solid;
        border-width: 2px;
        border-color: #3a3a3a;
        border-radius: 30px;
        background-color: #232858;
        text-align: center;

        .benefitImage {
          padding-right: 20px;
          padding-left: 20px;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#a200fa),
            to(#6600af)
          );
          background-image: linear-gradient(180deg, #a200fa, #6600af);
          max-width: 100%;
          vertical-align: middle;
          display: inline-block;
        }

        .contentWrapper {
          padding: 20px;
          flex: 1;

          .title {
            margin-bottom: 10px;
            color: #ffd915;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .description {
            opacity: 0.8;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .clubBenefits .container .benefitsGrid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .clubBenefits .container .benefitsGrid {
    grid-template-columns: 1fr;
  }
}

.wrapper {
  height: 100%;
}
</style>
