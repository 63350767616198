<template>
  <div class="roadMap">
    <div class="container">
      <animated-component>
        <img
          src="https://astrofrens-metadata.s3.amazonaws.com/assets/orbit.svg"
          loading="lazy"
          alt="Galaxy Icon"
          class="icon"
        />
      </animated-component>
      <animated-component>
        <div class="heading">Galaxy roadmap</div>
      </animated-component>
      <animated-component>
        <div class="paragraph">
          We're in this for the long haul! But this journey through the galaxy
          will be determined by your choices and interactions.
        </div>
      </animated-component>

      <div class="list">
        <div v-for="(item, index) in roadmapListItems" :key="index">
          <RoadMapListItem :data="item" :isDone="item.isDone" />
          <div class="line"></div>
        </div>
        <RoadMapListItem
          :data="{
            title: 'Rickstro Bulls',
            subtitle:
              'At 100% sold out Bull owners will be able to claim a free Rickstro Bull NFT!',
            percentage: '100%',
            image:
              'https://astrofrens-metadata.s3.amazonaws.com/assets/rickstro-small.gif'
          }"
          :isDone="true"
          :isLast="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RoadMapListItem from './RoadMapListItem.vue';

export default {
  components: {
    RoadMapListItem
  },

  setup() {
    const roadmapListItems = [
      {
        title: 'More ETH',
        subtitle:
          'At 25% sold out we will add 5 ETH to the community treasury.',
        percentage: '25%',
        image:
          'https://astrofrens-metadata.s3.amazonaws.com/assets/eth-icon.png',
        isDone: true
      },
      {
        title: 'More ETH + Airdrop',
        subtitle:
          'At 40% sold out we will add another 10 ETH to the community treasury. 10 lucky holders will be airdropped one Astro Bull!',
        percentage: '40%',
        image:
          'https://astrofrens-metadata.s3.amazonaws.com/assets/eth-icon.png',
        isDone: true
      },
      {
        title: 'Merch Drop',
        subtitle:
          ' At 50% sold out we will begin planning a future merch drop. Including an exclusive statue collectible.',
        percentage: '50%',
        image:
          'https://astrofrens-metadata.s3.amazonaws.com/assets/merch-icon.png',
        isDone: true
      },
      {
        title: 'Airdrop',
        subtitle:
          'At 65% sold out 20 lucky holders will be airdropped one Astro Bull!',
        percentage: '65%',
        image:
          'https://astrofrens-metadata.s3.amazonaws.com/assets/airdrop-icon.png',
        isDone: true
      },
      {
        title: 'More ETH',
        subtitle:
          'At 75% sold out we will add another 10 ETH to the community treasury.',
        percentage: '75%',
        image:
          'https://astrofrens-metadata.s3.amazonaws.com/assets/eth-icon.png',
        isDone: true
      },
      {
        title: 'Community Grant',
        subtitle: 'At 85% sold out there will be a Community Grant of 20 ETH.',
        percentage: '85%',
        image:
          'https://astrofrens-metadata.s3.amazonaws.com/assets/community-icon.png',
        isDone: true
      }
    ];

    return {
      roadmapListItems
    };
  }
};
</script>
<style scoped lang="scss">
.roadMap {
  padding: 60px 20px;
  background-image: linear-gradient(180deg, #000, rgba(0, 0, 0, 0.35)),
    url('https://astrofrens-metadata.s3.amazonaws.com/assets/galaxy-background.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: #fff;

    .icon {
      display: block;
      width: 100px;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      max-width: 100%;
      vertical-align: middle;
    }

    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .paragraph {
      max-width: 58ch;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      font-size: 18px;
      text-align: center;
    }

    .list {
      margin-top: 80px;
      margin-bottom: 40px;

      .line {
        width: 3px;
        height: 60px;
        margin-right: auto;
        margin-left: auto;
        background-color: hsla(0, 0%, 100%, 0.5);
      }
    }
  }
}
</style>
