<template>
  <div class="specsSection">
    <animated-component>
      <div class="container">
        <div>
          <div class="heading">The Specs</div>
          <div class="subtitle">
            Each Astro Bull is unique,
            <strong>programmatically generated</strong> from over 100,000
            possible outcomes weighted by rarity. Traits include horns, outfits,
            expressions, eyes, skin, headwear, backgrounds and more!<br /><br />The
            1st generation consists of <strong>10,000</strong> generated
            bulls.<br /><br />The bulls are stored as ERC-1155 tokens on the
            Ethereum blockchain.<br />
          </div>
        </div>

        <img
          src="https://astrofrens-metadata.s3.amazonaws.com/assets/bulls-grid.gif"
          loading="lazy"
          alt="Specs Bulls Image"
          class="specs-image"
        />
      </div>
    </animated-component>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.specsSection {
  background-color: #1b2050;
  padding: 60px 20px;

  .container {
    display: grid;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 40px;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    color: #fff;

    .heading {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .subtitle {
      max-width: 60ch;
      margin-right: auto;
      margin-left: auto;
      font-size: 18px;
      text-align: center;
    }

    .specs-image {
      width: 500px;
      max-width: 100%;
    }
  }
}

@media (max-width: 830px) {
  .specsSection .container {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
}
</style>
