'use strict';

// Imports.
import { mintService } from '../../services';
import { burnService } from '../../services';
import router from '/src/router';

// Specified actions for mutating the alert state.
export default {
  // Use the mint service to get a list of available shop items.
  async burn({ dispatch, commit }, tokenData, address) {
    try {
      console.log('burning...');
      await burnService.redeemToken(tokenData.ids, tokenData.address, dispatch);
      router.go(-1);
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async checkRedemption({ dispatch, commit }, tokenData) {
    try {
      let redemptionState = await burnService.checkRedemption(
        tokenData.check.address,
        tokenData.check.tokenId
      );
      return redemptionState;
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async reroll({ dispatch, commit }, { ids }, address) {
    try {
      await burnService.reroll(ids, dispatch);
      router.go(-1);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async checkNotary({ dispatch, commit }, { tokenId }) {
    try {
      console.log(tokenId)
      let redemptionState = await burnService.checkNotary(
        tokenId
      );
      return redemptionState;

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
