<template>
  <div class="mintSection">
    <div class="container">
      <animated-component>
        <div v-if="isLive" if class="intro mintingLive">
          Minting Is SOLD OUT!
        </div>
      </animated-component>
      <animated-component>
        <!-- <div v-if="isLive" class="joinTheClub">
          <strong>Join the Club</strong><br />Mint a Fren Here:
        </div> -->
      </animated-component>
      <animated-component>
        <Button
          onclick="window.open('https://opensea.io/collection/astro-frens', '_blank');"
        >
          Find on Opensea
        </Button>
        <!-- <Button v-if="isLive" @click="navigateTo('mint')" class="button">
          Mint Now
        </Button> -->
        <div v-if="!isLive" class="mintingWrapper">
          Minting starting in
          <Countdown class="space" :startDate="store.state.mint.startDate" />
        </div>
      </animated-component>
    </div>
  </div>
</template>
<script>
// Imports.
import router from '/src/router';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import Button from '/src/components/ui/Button.vue';
import Countdown from './Countdown.vue';

export default {
  components: {
    Button,
    Countdown
  },

  setup() {
    const store = useStore();
    const navigateTo = route => {
      router.push(route);
    };

    const now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    const isLive = computed(() => {
      return store.state.mint.startDate < now.value;
    });

    return {
      navigateTo,
      isLive,
      store
    };
  }
};
</script>
<style scoped lang="scss">
.mintSection {
  display: flex;
  min-height: 90vh;
  align-items: center;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url('https://astrofrens-metadata.s3.amazonaws.com/assets/main_banner.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, 2739px;
  background-repeat: repeat, repeat;
  // background-attachment: scroll, fixed;
  padding: 60px 20px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: white;

    .mintingWrapper {
      min-width: 200px;
      padding: 20px 30px;
      background-image: linear-gradient(180deg, #ffd915, #ffa41c);
      color: #000;
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      display: flex;
      white-space: nowrap;

      .space {
        margin-left: 10px;
      }
    }

    .intro {
      max-width: 56ch;
      margin-right: auto;
      margin-left: auto;
      font-size: 28px;
      text-align: center;
    }

    .mintingLive {
      margin-bottom: 20px;
      padding: 19px 40px;
      background-color: #ff1e1e;
      box-shadow: 4px 4px 20px -7px rgb(0 0 0 / 35%);
      color: #fff;
      font-size: 40px;
      font-weight: 700;
    }

    .joinTheClub {
      max-width: 60ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      font-size: 30px;
      text-align: center;
    }

    .button {
      border-radius: 50px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
      font-size: 30px;
      font-weight: 700;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 990px) {
  .mintSection .container .mintingWrapper {
    font-size: 4vw;
  }
}
</style>
