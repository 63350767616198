<template>
  <div>
    <SplashArea id="splash" />
    <AboutSection id="about" />
    <SpecsSection id="specs" />
    <ClubBenefitsSection id="club" />
    <RoadMapSection id="roadmap" />
    <RickstroSection />
    <CommunitySection />
    <FairDropSection id="fairdrop" />
    <FAQSection id="faq" />
    <MintSection id="mint" />
    <Footer />
  </div>
</template>
<script>
import SplashArea from './components/SplashArea.vue';
import AboutSection from './components/AboutSection.vue';
import SpecsSection from './components/SpecsSection.vue';
import ClubBenefitsSection from './components/ClubBenefitsSection.vue';
import RoadMapSection from './components/RoadMapSection.vue';
import FairDropSection from './components/FairDropSection.vue';
import RickstroSection from './components/RickstroSection.vue';
import CommunitySection from './components/CommunitySection.vue';
import FAQSection from './components/FAQSection.vue';
import MintSection from './components/MintSection.vue';
import Footer from '../layout/Footer.vue';

export default {
  components: {
    SplashArea,
    AboutSection,
    SpecsSection,
    ClubBenefitsSection,
    RoadMapSection,
    FairDropSection,
    RickstroSection,
    CommunitySection,
    FAQSection,
    MintSection,
    Footer
  }
};
</script>
<style scoped lang="scss"></style>
