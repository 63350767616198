<template>
  <div class="community">
    <div class="container">
      <animated-component>
        <img
          src="https://astrofrens-metadata.s3.amazonaws.com/assets/community.png"
          loading="lazy"
          alt="Community Photo"
          class="image"
        />
      </animated-component>
      <animated-component>
        <h2>Join the community</h2>
      </animated-component>
      <animated-component>
        <div class="description">
          Learn more about AstroFrens, chat with the team, others in the
          community, and have your say in shaping the future of Astro Frens.
        </div>
      </animated-component>
      <div class="socialsGrid">
        <!-- Twitter -->
        <animated-component animationType="left">
          <div
            class="socialTile twitter"
            @click="navigateTo('https://twitter.com/AstroFrensNFT')"
          >
            <div class="socialIcon">
              <TwitterIcon />
            </div>
            <div class="socialTitle">Twitter</div>
            <div class="socialDescription">
              Follow <strong>@AstroFrensNFT</strong> for updates and news.
            </div>
          </div>
        </animated-component>
        <!-- Discord -->
        <animated-component animationType="right">
          <div
            class="socialTile"
            @click="navigateTo('https://discord.gg/wUR4d62qKe')"
          >
            <div class="socialIcon">
              <DiscordIcon />
            </div>
            <div class="socialTitle">Discord</div>
            <div class="socialDescription">
              Chat in real time with the Astro Frens community.
            </div>
          </div>
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script>
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
export default {
  components: {
    TwitterIcon,
    DiscordIcon
  },

  methods: {
    navigateTo(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
<style scoped lang="scss">
.community {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url('https://astrofrens-metadata.s3.amazonaws.com/assets/galaxy-background.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  padding: 60px 20px;
  color: white;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .image {
      display: block;
      width: 300px;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
    }

    .description {
      max-width: 58ch;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      font-size: 18px;
      text-align: center;
    }

    h2 {
      max-width: 41ch;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      color: #ffd915;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .socialsGrid {
      display: grid;
      max-width: 1280px;
      margin-right: auto;
      margin-left: auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      .socialTile {
        display: flex;
        padding: 30px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border: 2px solid #3a3a3a;
        border-radius: 30px;
        background-color: #1b2050;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
        transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #fff;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          transform: translate(10px, -10px);
        }

        &:active {
          transform: translate(0px, 0px);
        }
        .socialIcon svg {
          display: flex;
          width: 60px;
          height: 50px;
          margin-bottom: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .socialTitle {
          margin-bottom: 10px;
          font-size: 30px;
          font-weight: 600;
        }

        .socialDescription {
          opacity: 0.8;
          font-size: 18px;
        }
      }

      .twitter {
        color: #1da1f2;
      }
    }
  }
}

@media (max-width: 660px) {
  .community .container .socialsGrid {
    grid-template-columns: 1fr;
  }
}
</style>
